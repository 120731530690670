export enum TbVehicleOrderProps {
  ID = 'id',
  ORDER_DATE = 'orderCreatedDate',
  ORDER_NUMBER = 'orderNumber',
  DISTRIBUTOR_ID = 'distributorId',
  DISTRIBUTOR_NAME = 'distributorName',
  DISTRIBUTOR_GROUP = 'distributorGroup',
  SOURCE_LOCATION = 'sourceLocalCode',
  VEHICLE_MODEL = 'vehicleModel',
  ORDER_AMOUNT = 'orderAmount',
  ORDER_CURRENCY = 'orderCurrency',
  REASON = 'reason',
  ACCOUNT_STATUS = 'accountStatus',
  OPER_STATUS = 'operStatus',
  RISK_CLASS = 'riskClassification',
  TASK_STATUS = 'taskStatus',
  PRODUCT_LINE = 'productLine',
  ORDER_ID = 'wwtfOrderId',
  PLANT_CODE = 'plantCode'
}

export enum TbVehicleOrderFilterProps {
  ORDER_DATE_FROM = 'orderDateFrom',
  ORDER_DATE_TO = 'orderDateTo',
  DISTRIBUTOR_CODE_NAME = 'distributorCodeName',
  SOURCE_LOCATION = 'sourceLocation',
  VEHICLE_MODEL = 'vehicleModel',
  ORDER_NUMBER = 'orderNumber',
  ACCOUNT_STATUS = 'accountStatus',
  OPPER_STATUS = 'operStatus',
  RISK_CLASS = 'riskClass',
  ORDER_ID = 'wwtfOrderId'
}

export enum TbVehicleOrderAction {
  MODIFY = 'modify',
  DELETE = 'delete',
  REJECT = 'reject',
  APPROVE = 'approve',
  EXCEPTION = 'exception'
}

export enum VehicleOrderTaskboardTestIds {
  FILTER_TOOLBAR = 'vehicle-order-taskboard__filter-toolbar',
  TABLE = 'vehicle-order-taskboard__table'
}
