import { TaskBoardRequestType, TaskBoardStatus } from '@pages/task-board/enums/task-board.enum';
import {
  DistributorGroupSection,
  DistributorProps,
  DistributorSection
} from '@pages/distributor/enums/distributor-manager.enum';
import { DistributorManagerConstantMock } from '@pages/distributor/constant/distributor-manager.constant.mock';
import { FundingBankProps } from '@pages/distributor/enums/funding-bank.enum';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { GuarantorProps, InsurerProps, SecurityType } from '@pages/distributor/enums/securities.enum';
import { BlanketStatus, ProductLineProps } from '@pages/distributor/enums/product-lines.enum';
import { BlanketsProps } from '@pages/distributor/enums/blankets.enum';
import { SourceLocationProps } from '@pages/distributor/enums/source-location-mapping.enum';
import { TbGeneralMaintenanceProps } from '../enums/tb-general-maintenance.enum';
import { CountryConstantMock } from '@pages/general-maintenance/pages/country/constant/country.constant.mock';
import { CountryProps } from '@pages/general-maintenance/pages/country/enums/country.enum';
import { TbVehicleOrderProps } from '../enums/tb-vehicle-order.enum';

export class TaskBoardConstantMock {
  static taskBoardList = {
    generalMaintenance: [
      {
        [TbGeneralMaintenanceProps.ID]: 1,
        [TbGeneralMaintenanceProps.CREATED_TIMESTAMP]: '2024/07/05',
        [TbGeneralMaintenanceProps.TYPE]: TaskBoardRequestType.COUNTRY_RATING_UPDATE,
        [TbGeneralMaintenanceProps.INITIATOR_CDSID]: 'REQUESTOR',
        [TbGeneralMaintenanceProps.OWNER_CDSID]: 'AMART736',
        [TbGeneralMaintenanceProps.STATUS]: TaskBoardStatus.WAITING_APPROVAL,
        [TbGeneralMaintenanceProps.AFFECTED_RESOURCE_ID]: 'ES'
      },
      {
        [TbGeneralMaintenanceProps.ID]: 2,
        [TbGeneralMaintenanceProps.CREATED_TIMESTAMP]: '2024/07/05',
        [TbGeneralMaintenanceProps.TYPE]: TaskBoardRequestType.BASE_RATE_UPDATE,
        [TbGeneralMaintenanceProps.INITIATOR_CDSID]: 'REQUESTOR',
        [TbGeneralMaintenanceProps.OWNER_CDSID]: 'AMART736',
        [TbGeneralMaintenanceProps.STATUS]: TaskBoardStatus.WAITING_APPROVAL,
        [TbGeneralMaintenanceProps.AFFECTED_RESOURCE_ID]: 'AL'
      },
      {
        [TbGeneralMaintenanceProps.ID]: 3,
        [TbGeneralMaintenanceProps.CREATED_TIMESTAMP]: '2024/07/05',
        [TbGeneralMaintenanceProps.TYPE]: TaskBoardRequestType.BASE_RATE_UPDATE,
        [TbGeneralMaintenanceProps.INITIATOR_CDSID]: 'REQUESTOR',
        [TbGeneralMaintenanceProps.OWNER_CDSID]: 'AMART736',
        [TbGeneralMaintenanceProps.STATUS]: TaskBoardStatus.REJECTED,
        [TbGeneralMaintenanceProps.AFFECTED_RESOURCE_ID]: 'AR'
      },
      {
        [TbGeneralMaintenanceProps.ID]: 4,
        [TbGeneralMaintenanceProps.CREATED_TIMESTAMP]: '2024/07/05',
        [TbGeneralMaintenanceProps.TYPE]: TaskBoardRequestType.COUNTRY_RATING_UPDATE,
        [TbGeneralMaintenanceProps.INITIATOR_CDSID]: 'REQUESTOR',
        [TbGeneralMaintenanceProps.OWNER_CDSID]: 'AMART736',
        [TbGeneralMaintenanceProps.STATUS]: TaskBoardStatus.WAITING_APPROVAL,
        [TbGeneralMaintenanceProps.AFFECTED_RESOURCE_ID]: 'AT'
      },
      {
        [TbGeneralMaintenanceProps.ID]: 5,
        [TbGeneralMaintenanceProps.CREATED_TIMESTAMP]: '2024/07/05',
        [TbGeneralMaintenanceProps.TYPE]: TaskBoardRequestType.COUNTRY_RATING_UPDATE,
        [TbGeneralMaintenanceProps.INITIATOR_CDSID]: 'REQUESTOR',
        [TbGeneralMaintenanceProps.OWNER_CDSID]: 'AMART736',
        [TbGeneralMaintenanceProps.STATUS]: TaskBoardStatus.REJECTED,
        [TbGeneralMaintenanceProps.AFFECTED_RESOURCE_ID]: 'AU'
      }
    ],
    vehicleOrder: [
      {
        [TbVehicleOrderProps.ID]: '1',
        [TbVehicleOrderProps.ORDER_DATE]: '2024/07/05',
        [TbVehicleOrderProps.ORDER_NUMBER]: 'XXXX',
        [TbVehicleOrderProps.DISTRIBUTOR_ID]: 'HEDIN',
        [TbVehicleOrderProps.DISTRIBUTOR_NAME]: 'HEDIN',
        [TbVehicleOrderProps.SOURCE_LOCATION]: 'DUMM',
        [TbVehicleOrderProps.VEHICLE_MODEL]: 'V769 Transist Courier',
        [TbVehicleOrderProps.ORDER_AMOUNT]: 68434151678,
        [TbVehicleOrderProps.ACCOUNT_STATUS]: 'Active',
        [TbVehicleOrderProps.OPER_STATUS]: 'Active',
        [TbVehicleOrderProps.RISK_CLASS]: 'Status',
        [TbVehicleOrderProps.TASK_STATUS]: TaskBoardStatus.WAITING_APPROVAL
      },
      {
        [TbVehicleOrderProps.ID]: '2',
        [TbVehicleOrderProps.ORDER_DATE]: '2024/07/05',
        [TbVehicleOrderProps.ORDER_NUMBER]: 'XXXX',
        [TbVehicleOrderProps.DISTRIBUTOR_ID]: 'HEDIN',
        [TbVehicleOrderProps.DISTRIBUTOR_NAME]: 'HEDIN',
        [TbVehicleOrderProps.SOURCE_LOCATION]: 'DUMM',
        [TbVehicleOrderProps.VEHICLE_MODEL]: 'V769 Transist Courier',
        [TbVehicleOrderProps.ORDER_AMOUNT]: 68434151678,
        [TbVehicleOrderProps.ACCOUNT_STATUS]: 'Active',
        [TbVehicleOrderProps.OPER_STATUS]: 'Active',
        [TbVehicleOrderProps.RISK_CLASS]: 'Status',
        [TbVehicleOrderProps.TASK_STATUS]: TaskBoardStatus.PENDING
      },
      {
        [TbVehicleOrderProps.ID]: '3',
        [TbVehicleOrderProps.ORDER_DATE]: '2024/07/05',
        [TbVehicleOrderProps.ORDER_NUMBER]: 'XXXX',
        [TbVehicleOrderProps.DISTRIBUTOR_ID]: 'HEDIN',
        [TbVehicleOrderProps.DISTRIBUTOR_NAME]: 'HEDIN',
        [TbVehicleOrderProps.SOURCE_LOCATION]: 'DUMM',
        [TbVehicleOrderProps.VEHICLE_MODEL]: 'V769 Transist Courier',
        [TbVehicleOrderProps.ORDER_AMOUNT]: 68434151678,
        [TbVehicleOrderProps.ACCOUNT_STATUS]: 'Active',
        [TbVehicleOrderProps.OPER_STATUS]: 'Active',
        [TbVehicleOrderProps.RISK_CLASS]: 'Status',
        [TbVehicleOrderProps.TASK_STATUS]: TaskBoardStatus.WAITING_APPROVAL
      },
      {
        [TbVehicleOrderProps.ID]: '4',
        [TbVehicleOrderProps.ORDER_DATE]: '2024/07/05',
        [TbVehicleOrderProps.ORDER_NUMBER]: 'XXXX',
        [TbVehicleOrderProps.DISTRIBUTOR_ID]: 'HEDIN',
        [TbVehicleOrderProps.DISTRIBUTOR_NAME]: 'HEDIN',
        [TbVehicleOrderProps.SOURCE_LOCATION]: 'DUMM',
        [TbVehicleOrderProps.VEHICLE_MODEL]: 'V769 Transist Courier',
        [TbVehicleOrderProps.ORDER_AMOUNT]: 68434151678,
        [TbVehicleOrderProps.ACCOUNT_STATUS]: 'Active',
        [TbVehicleOrderProps.OPER_STATUS]: 'Active',
        [TbVehicleOrderProps.RISK_CLASS]: 'Status',
        [TbVehicleOrderProps.TASK_STATUS]: TaskBoardStatus.WAITING_APPROVAL
      },
      {
        [TbVehicleOrderProps.ID]: '5',
        [TbVehicleOrderProps.ORDER_DATE]: '2024/07/05',
        [TbVehicleOrderProps.ORDER_NUMBER]: 'XXXX',
        [TbVehicleOrderProps.DISTRIBUTOR_ID]: 'HEDIN',
        [TbVehicleOrderProps.DISTRIBUTOR_NAME]: 'HEDIN',
        [TbVehicleOrderProps.SOURCE_LOCATION]: 'DUMM',
        [TbVehicleOrderProps.VEHICLE_MODEL]: 'V769 Transist Courier',
        [TbVehicleOrderProps.ORDER_AMOUNT]: 68434151678,
        [TbVehicleOrderProps.ACCOUNT_STATUS]: 'Active',
        [TbVehicleOrderProps.OPER_STATUS]: 'Active',
        [TbVehicleOrderProps.RISK_CLASS]: 'Status',
        [TbVehicleOrderProps.TASK_STATUS]: TaskBoardStatus.REJECTED
      }
    ]
  };

  static distributor = {
    distributorRef: DistributorManagerConstantMock.distributorById[1],
    distributor: {
      ...DistributorManagerConstantMock.distributorById[1],
      [DistributorSection.GENERAL_INFORMATION]: {
        ...DistributorManagerConstantMock.distributorById[1][DistributorSection.GENERAL_INFORMATION],
        [DistributorProps.COUNTRY]: {
          [CountryProps.CODE]: 'ES',
          [CountryProps.NAME]: 'SPAIN',
          [CountryProps.MEMBERSHIP]: 'ES'
        },
        [DistributorProps.NAME]: 'NEW NAME',
        [DistributorProps.DELETION_SUSPENSION_ORDER]: false
      },
      [DistributorSection.SECURITIES_AND_CREDIT_LIMITS]: {
        ...DistributorManagerConstantMock.distributorById[1][DistributorSection.SECURITIES_AND_CREDIT_LIMITS],
        [DistributorProps.GROUP_CREDIT_LIMITS]: {
          ...DistributorManagerConstantMock.distributorById[1][DistributorSection.SECURITIES_AND_CREDIT_LIMITS][
            DistributorProps.GROUP_CREDIT_LIMITS
          ],
          [DistributorGroupProps.NAME]: 'NEW NAME',
          [DistributorGroupProps.CODE]: 'NEW CODE'
        },
        [DistributorProps.FUNDING_BANK]: [
          {
            [FundingBankProps.NAME]: 'New Bank',
            [FundingBankProps.FUNDING_BANK_AMOUNT]: 200,
            [FundingBankProps.FUNDING_BANK_PERCENTAGE]: 100
          }
        ],
        [DistributorProps.SECURITIES]: {
          [SecurityType.GUARANTORS]: [
            {
              ...DistributorManagerConstantMock.guarantor,
              [GuarantorProps.NAME]: 'New Guarantor Name'
            }
          ],
          [SecurityType.INSURERS]: [
            {
              ...DistributorManagerConstantMock.guarantor,
              [InsurerProps.NAME]: 'New Insurer Name'
            }
          ]
        }
      },
      [DistributorSection.PRODUCT_LINES_AND_BLANKETS]: {
        [DistributorProps.PRODUCT_LINES]: [
          { ...DistributorManagerConstantMock.productLine, [ProductLineProps.PL_ID]: 1 },
          { ...DistributorManagerConstantMock.productLine, [ProductLineProps.PL_ID]: 2 }
        ],
        [DistributorProps.BLANKETS]: [
          {
            [BlanketsProps.ID]: 1,
            [BlanketsProps.PRODUCT_LINE_ID]: 'New Blanket',
            [BlanketsProps.CAPITAL]: 100,
            [BlanketsProps.STATUS]: BlanketStatus.ACTIVE
          }
        ]
      },
      [DistributorSection.SOURCE_LOCATION_MAPPING]: [
        DistributorManagerConstantMock.sourceLocation,
        { ...DistributorManagerConstantMock.sourceLocation, [SourceLocationProps.SOURCE_LOCATION_CODE]: '22261719' }
      ],
      [DistributorSection.OBSERVATIONS]: 'New Observations'
    }
  };

  static group = {
    groupRef: DistributorManagerConstantMock.distributorGroupById['1'],
    group: {
      ...DistributorManagerConstantMock.distributorGroupById['1'],
      [DistributorGroupSection.GENERAL_INFORMATION]: {
        ...DistributorManagerConstantMock.distributorGroupById['1'][DistributorGroupSection.GENERAL_INFORMATION],
        [DistributorGroupProps.COUNTRY]: {
          [CountryProps.NAME]: 'Spain',
          [CountryProps.CODE]: 'ES',
          [CountryProps.MEMBERSHIP]: 'NEW'
        }
      }
    }
  };

  static country = {
    countryRef: CountryConstantMock.countriesById['1'],
    country: {
      code: 'ES',
      name: 'SPAIN',
      operationalRegion: 'EUB',
      euroIndicator: true,
      riskCode: 'A',
      finishVehicleAccounting: true,
      insuranceCmpnyEndoresed: 'AINPAYNEVR',
      insuranceExceptionAmount: 1000000.0,
      insuranceExceptionCurrency: 'EUR',
      insuranceExceptionEffectiveTimestamp: '2024-05-28T15:23:42.439286Z',
      insuranceExceptionExpiryTimestamp: '2038-02-06T15:23:42.439286Z',
      status: 'WAITING_APPROVAL'
    }
  };
}
