export enum ConfirmDialogProps {
  TITLE_KEY = 'titleKey',
  SUMMARY_KEY = 'summaryKey',
  CONFIRM_LABEL_KEY = 'confirmLabelKey',
  CONFIRM_CALLBACK = 'confirmCallback',
  CANCEL_LABEL_KEY = 'cancelLabelKey',
  CANCEL_CALLBACK = 'cancelCallback',
  SIZE = 'dialogSize',
  HIDE_DISMISS = 'hideDismiss'
}
