import { ColorType } from 'app/shared/enums/color.enum';
import { GeneralDetailsStatus, StatusConfigProps } from '../enums/general-details-enum';
import { GenericObject } from '@utils/models/Types';
import { StatusMap } from 'app/core/types/status.type';

export class GeneralDetailsConstant {
    static statusMapConfig: GenericObject<StatusMap> = {
        [GeneralDetailsStatus.ACTIVE]: {
          [StatusConfigProps.COLOR]: ColorType.SUCCESS,
          [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.ACTIVE'
        },
        [GeneralDetailsStatus.SUSPENDED]: {
          [StatusConfigProps.COLOR]: ColorType.CAUTION,
          [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.SUSPENDED'
        },
        [GeneralDetailsStatus.CLOSED]: {
          [StatusConfigProps.COLOR]: ColorType.DANGER,
          [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.CLOSED'
        },
        [GeneralDetailsStatus.WAITING]: {
          [StatusConfigProps.COLOR]: ColorType.CAUTION,
          [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.WAITING_APPROVAL'
        },
        [GeneralDetailsStatus.PENDING]: {
          [StatusConfigProps.COLOR]: ColorType.PURPLE,
          [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.PENDING'
        },
        [GeneralDetailsStatus.REVIEWED]: {
          [StatusConfigProps.COLOR]: ColorType.BLUE,
          [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.REVIEWED'
        }
      };
}