import { Injectable } from '@angular/core';
import { BackendService } from '@services/backend.service';
import { TaskBoardEndpoints } from '@pages/task-board/constants/task-board.endpoints.constant';
import { Observable, map, of } from 'rxjs';
import { TaskBoardConstantMock } from '@pages/task-board/constants/task-board.constant.mock';
import { TbDistributorGroupApi } from '@pages/task-board/types/tb-distributors.type';
import { RouteParams } from '../../../core/enums/route.enum';
import { ApiProps } from '../../../core/enums/api.enum';
import { MaintenanceTask } from '@pages/task-board/types/task-board.type';
import { ApiResponse } from 'app/core/types/api.type';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { DistributionBodiesProps } from '../enums/task-board.enum';
import { TaskBoardConstant } from '../constants/task-board.constant';
import { GenericObject } from '@utils/models/Types';
import { ToastMsgService } from '@services/toast-msg.service';
import { MsgProps } from 'app/shared/enums/backend.enum';

@Injectable({
  providedIn: 'root'
})
export class TaskBoardService {
  constructor(private _backendService: BackendService, private _toastService: ToastMsgService) {}

  get() {
    return this._backendService.get(TaskBoardEndpoints.config.GENERAL_MAINTENANCE.LIST, {
      resultWithinProp: [ApiProps.RESULT, 'tasks']
    });
  }


  getVehicleOrderList() {
    return this._backendService.get(TaskBoardEndpoints.config.VEHICLE_ORDER.LIST).pipe(
      map((response: ApiResponse<any>) => {
        return NextObjectHelper.getPropertyFromObject(response, [ApiProps.RESULT,DistributionBodiesProps.ORDERS], []);            
    }))
  }

  getVehicleOrder(id: number) {
    return this._backendService.get(TaskBoardEndpoints.config.VEHICLE_ORDER.ITEM, {
      urlParams: { [RouteParams.ID]: id },
      resultWithinProp: [ApiProps.RESULT, 'order']
    })
  }

  getTask(id: number): Observable<MaintenanceTask> {
    return this._backendService.get(TaskBoardEndpoints.config.TASK, {
      urlParams: { [RouteParams.ID]: id },
      resultWithinProp: [ApiProps.RESULT, 'task']
    });
  }

  getDistributorGroup(id: number): Observable<TbDistributorGroupApi> {
    return of(TaskBoardConstantMock.group);
  }

  getInfoTask(id: number): Observable<any> {
    return this._backendService.get(TaskBoardEndpoints.config.TASK, {
      urlParams: { [RouteParams.ID]: id },
      resultWithinProp: [ApiProps.RESULT, 'task']
    })
  }

  approveTask(section: string, id: number | string, idTask: number): Observable<any> {
    const endpointItem = TaskBoardConstant.endpointItemBySectionApprove[section].url;

    return this._backendService.put(endpointItem,{}, {
      urlParams: { [RouteParams.ID]: id , [RouteParams.TASK_ID]: idTask},
    }).pipe(
      map((response: ApiResponse<any>) => {
        const country = NextObjectHelper.getPropertyFromObject(response, [ApiProps.RESULT,DistributionBodiesProps.COUNTRY]);
          if(country) {
            return {...country}
          }
      })
    );
  }

  rejectedTask(section: string, id: number | string, idTask: number): Observable<any> {
    const endpointItem = TaskBoardConstant.endpointItemBySectionReject[section].url;

    return this._backendService.put(endpointItem,{}, {
      urlParams: { [RouteParams.ID]: id , [RouteParams.TASK_ID]: idTask},
    }).pipe(
      map((response: ApiResponse<any>) => {
        const country = NextObjectHelper.getPropertyFromObject(response, [ApiProps.RESULT,DistributionBodiesProps.COUNTRY]);
          if(country) {
            return {...country}
          }
      })
    );
  }

  deleteTask(section: string, id: number | string, idTask: number | string): Observable<any> {
    const endpointItem = TaskBoardConstant.endpointItemBySectionDelete[section].url;

    return this._backendService.delete(endpointItem,{}, {
      urlParams: { [RouteParams.ID]: id , [RouteParams.TASK_ID]: idTask},
    })
  }
}
