<div class="general-details">
  <next-card [hasBorder]="true"
             [hasNoneBorderRadius]="true"
             [isHorizontalContent]="true"
  >
    <header-detail
      [header]="header!"
      [headerKey]="headerKey!"
      [subTitle]="(subTitle) |translate"
      [status]="status"
      [statusMap]="GeneralDetailsConstant.statusMapConfig"
      [iconType]="NextIcon.CLOCK"
      [iconColor]="IconColor.BRAND_DISABLED"
      [iconPosition]="LinkButtonPosition.LEFT"
      headerTemplate>
    </header-detail>
    <ng-container>
      <data-card
        [dataObject]="data"
        [dataObjectRef]="dataRef"
        [configList]="dataTitleConfig"
        [isHorizontalContent]="true" class="group-details__item"
      ></data-card>
    </ng-container>
  </next-card>
</div>
