export class CountryConstantMock {
    static countriesList = {
        'result': {
          'countries': [
            {
              'country': 'BH',
              'name': 'BAHRAIN',
              'operationalRegion': 'MEA',
              'euroIndicator': false,
              'riskCode': 'LOW',
              'finishVehicleAccounting': true,
              'insuranceCmpnyEndoresed': 'AINPAYNEVR',
              'insuranceExceptionAmount': 2000000.00,
              'insuranceExceptionCurrency': 'EUR',
              'insuranceExceptionEffectiveTimestamp': '2024-05-28T15:23:42.439286Z',
              'insuranceExceptionExpiryTimestamp': '2038-02-06T15:23:42.439286Z'
          }
          ] 
        }
    }

    static countriesById: any = {
      '1': {
        'code': 'ES',
        'name': 'SPAIN',
        'operationalRegion': 'EUB',
        'euroIndicator': true,
        'riskCode': 'B',
        'finishVehicleAccounting': true,
        'insuranceCmpnyEndoresed': 'AINPAYNEVR',
        'insuranceExceptionAmount': 2000000.00,
        'insuranceExceptionCurrency': 'EUR',
        'insuranceExceptionEffectiveTimestamp': '2024-05-28T15:23:42.439286Z',
        'insuranceExceptionExpiryTimestamp': '2038-02-06T15:23:42.439286Z',
        'status': 'WAITING_APPROVAL'
      }
    }
}
