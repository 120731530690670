import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmDialogType } from '@components/organisms/confirm-dialog/confirm-dialog.type';
import { ConfirmDialogProps } from '@components/organisms/confirm-dialog/confirm-dialog.enum';

@Injectable({
  providedIn: 'root'
})
export class ConfirmServiceService {
  private _confirmSubject: Subject<ConfirmDialogType | undefined> = new Subject<ConfirmDialogType | undefined>();

  getConfirmObservable() {
    return this._confirmSubject;
  }

  hide() {
    this._confirmSubject.next(undefined);
  }

  asyncConfirmation(config: ConfirmDialogType): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._confirmSubject.next({
        [ConfirmDialogProps.CANCEL_LABEL_KEY]: 'BUTTONS.CANCEL',
        [ConfirmDialogProps.CONFIRM_CALLBACK]: resolve,
        [ConfirmDialogProps.CANCEL_CALLBACK]: reject,
        ...config
      });
    });
  }
}
