import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconColor, IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { DataTitleConfig } from '@components/atoms/visual/components/data-title/data-title.config';
import { DataTitleProps } from '@components/atoms/visual/components/data-title/data-title.enum';
import { Distributor } from '@pages/distributor/types/distributor.type';
import { CountryProps } from '@pages/general-maintenance/pages/country/enums/country.enum';
import { GeneralDetailsConstant } from './constant/general-details.constant';

@Component({
  selector: 'general-details',
  templateUrl: './general-details.component.html',
  styleUrl: './general-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralDetailsComponent implements OnInit {
  @Input() data: Distributor | CountryProps;
  @Input() dataRef: Distributor | CountryProps;
  @Input() header?: string;
  @Input() headerKey?: string;
  @Input() dataTitleConfig: DataTitleConfig[];
  @Input() status?: any;

  subTitle: string;

  protected readonly GeneralDetailsConstant = GeneralDetailsConstant;
  protected readonly IconSizeModifier = IconSizeModifier;
  protected readonly IconColor = IconColor;
  protected readonly NextIcon = NextIcon;
  protected readonly LinkButtonPosition = LinkButtonPosition;
  protected readonly DataTitleProps = DataTitleProps;

  ngOnInit(): void {
    this.subTitle = 'REVIEW_MODIFIED_FIELDS.VIEW_HISTORY';
  }
}
