export enum GeneralDetailsStatus {
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    CLOSED = 'CLOSED',
    WAITING = 'WAITING_APPROVAL',
    PENDING = 'PENDING',
    REVIEWED = 'REVIEWED',
    ARCHIVED = 'ARCHIVED'
}

export enum StatusConfigProps {
    KEY = 'statusKey',
    COLOR = 'colorType'
}