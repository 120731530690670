export enum TbGeneralMaintenanceProps {
    ID = 'id',
    NAME = 'name',
    OWNER_CDSID = 'ownerCdsid',
    TYPE = 'type',
    AFFECTED_RESOURCE_ID = 'affectedResourceId',
    AFFECTED_RESOURCE_NAME = 'affectedResourceName',
    CREATED_TIMESTAMP = 'createdTimestamp',
    STATUS = 'status',
    REJECTION_REASON = 'rejectionReason',
    INITIATOR_CDSID = 'initiatorCdsid',
    REFERENCE = 'reference',
    JSON_PAYLOAD = 'jsonPayload',
    LATEST_EXECUTED_STEP = 'latestExecutedStep'
}

export enum TbGeneralMaintenanceAction {
    MODIFY = 'modify',
    DELETE = 'delete'
}

export enum TbGeneralMaintenanceFilterProps {
    CREATION_DATE_FROM = 'creationDateFrom',
    CREATION_DATE_TO = 'creationDateTo',
    REQUEST_TYPE = 'requestType'
}

export enum TbGeneralMaintenanceApiProps {
    COUNTRY_REF = 'countryRef'
}
  